import React, { useState, useEffect } from 'react';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { ColumnGroup } from 'primereact/columngroup';
import { Row } from 'primereact/row';
import moment from 'moment'
import CurrencyExchangeTwoToneIcon from '@mui/icons-material/CurrencyExchangeTwoTone';

import styled from "styled-components";

const HeaderTable = styled.div`
    text-align:center;
    color:#041E42;
    font-size:24px;
`

const IconHeader = styled(CurrencyExchangeTwoToneIcon)`
    vertical-align:text-bottom;
    margin-right:10px;
    width: 30px !important;
    height: 30px !important;
`

const HeaderTitulo = styled.span`
    vertical-align:text-bottom;
    margin-bottom:10px;
`

const TabelaReembolsos = (props) => {
    const [reembolsos, setReembolsos] = useState([]);
    const [tipoReembolso, setTipoReembolso] = useState("TERRESTRE")
    const [totalTarifaReembolso, setTotalTarifaReembolso] = useState(0.00);
    const [totalTaxaReembolso, setTotalTaxaReembolso] = useState(0.00);
    const [totalCartaoReembolso, setTotalCartaoReembolso] = useState(0.00);
    const [totalComissaoReembolso, setTotalComissaoReembolso] = useState(0.00);
    const [totalIncentivoReembolso, setTotalIncentivoReembolso] = useState(0.00);
    const [totalDescontoReembolso, setTotalDescontoReembolso] = useState(0.00);
    const [totalMultaReembolso, setTotalMultaReembolso] = useState(0.00);
    const [totalLiquidoReembolso, setTotalLiquidoReembolso] = useState(0.00);

    useEffect(() => {
        setReembolsos(props.fatura.reembolsos);
        setTipoReembolso(props.tipoReembolso);
        setTotalTarifaReembolso(formatNumberPosMount(props.fatura.totalTarifaReembolso));
        setTotalTaxaReembolso(formatNumberPosMount(props.fatura.totalTaxaReembolso));
        setTotalCartaoReembolso(formatNumberPosMount(props.fatura.totalCartaoReembolso));
        setTotalComissaoReembolso(formatNumberPosMount(props.fatura.totalComissaoReembolso));
        setTotalIncentivoReembolso(formatNumberPosMount(props.fatura.totalIncentivoReembolso));
        setTotalDescontoReembolso(formatNumberPosMount(props.fatura.totalDescontoReembolso));
        setTotalMultaReembolso(formatNumberPosMount(props.fatura.totalMultaReembolso));
        setTotalLiquidoReembolso(formatNumberPosMount(props.fatura.totalLiquidoReembolso));

    }, [reembolsos, totalTarifaReembolso, totalTaxaReembolso, totalCartaoReembolso, 
        totalComissaoReembolso, totalIncentivoReembolso, totalDescontoReembolso, totalMultaReembolso, 
        totalLiquidoReembolso, props.fatura.reembolsos, props.fatura.totalTarifaReembolso, 
        props.fatura.totalTaxaReembolso, props.fatura.totalCartaoReembolso, props.fatura.totalComissaoReembolso,
         props.fatura.totalIncentivoReembolso, props.fatura.totalDescontoReembolso, props.fatura.totalMultaReembolso,
          props.fatura.totalLiquidoReembolso, props.tipoReembolso]);

    const formatNumberPosMount = (number) => {
        const options = { minimumFractionDigits: 2, maximumFractionDigits: 2 }
        const formatNumber = new Intl.NumberFormat('pt-BR', options)
        return number !== "undefined" ? formatNumber.format(number) : 0.00;
    }

    const header = (
        <HeaderTable>
            <IconHeader /><HeaderTitulo>REEMBOLSOS - ADMs - ACMs</HeaderTitulo>
        </HeaderTable>
    );
    
    const options = { minimumFractionDigits: 2, maximumFractionDigits: 2 }
    const formatNumber = new Intl.NumberFormat('pt-BR', options)

    const tarifaBodyTemplate = (rowData) => {
        let value = rowData.tarifa;
        var formattedMoney = formatNumber.format(value);
        return formattedMoney;
    }

    const taxaBodyTemplate = (rowData) => {
        let value = rowData.taxa;
        var formattedMoney = formatNumber.format(value);
        return formattedMoney;
    }
    const cartaoBodyTemplate = (rowData) => {
        let value = rowData.cartao;
        var formattedMoney = formatNumber.format(value);
        return formattedMoney;
    }

    const comissaoBodyTemplate = (rowData) => {
        let value = rowData.comissao;
        var formattedMoney = formatNumber.format(value);
        return formattedMoney;
    }

    const incentivoBodyTemplate = (rowData) => {
        let value = rowData.incentivo;
        var formattedMoney = formatNumber.format(value);
        return formattedMoney;
    }

    const descontoBodyTemplate = (rowData) => {
        let value = rowData.desconto;
        var formattedMoney = formatNumber.format(value);
        return formattedMoney;
    }

    const liquidoBodyTemplate = (rowData) => {
        let value = rowData.liquido;
        var formattedMoney = formatNumber.format(value);
        return formattedMoney;
    }

    const multaBodyTemplate = (rowData) => {
        let value = rowData.multa;
        var formattedMoney = formatNumber.format(value);
        return formattedMoney;
    }
    const dataEmissaoBodyTemplate = (rowData) => {
        return moment(rowData.dataEmissao).format('DD/MM/YYYY')
    }

    const paxBodyTemplate = (rowData) => {
        let value = rowData.passageiro;
        var passageiros = value.split("%");
        return (
            <div className="flex flex-wrap gap-2">
                 { passageiros.map(pax => <><span>{ pax }</span><br></br></>) }
            </div>
        );
    }

    const reactFooter = (data) => {
        return (
            <React.Fragment>
                <td colSpan={11}>Observação: {data.obs}</td>
            </React.Fragment>
        );
    }

    let footerGeral =
        <ColumnGroup>
            <Row>
                <Column style={{ width: '400px', color: "white", backgroundColor: "#041E42" }} footer="" />
                <Column style={{ width: '150px', color: "white", backgroundColor: "#041E42" }} footer="" />
                <Column style={{ width: '200px', color: "white", backgroundColor: "#041E42" }} footer="" />
                <Column style={{ width: '200px', color: "white", backgroundColor: "#041E42" }} footer="" />
                <Column style={{ width: '300px', color: "white", backgroundColor: "#041E42" }} footer="Total Geral:" />
                <Column style={{ width: '150px', color: "white", backgroundColor: "#041E42" }} footer={totalTarifaReembolso} />
                <Column style={{ width: '150px', color: "white", backgroundColor: "#041E42" }} footer={totalTaxaReembolso} />
                <Column style={{ width: '150px', color: "white", backgroundColor: "#041E42" }} footer={totalMultaReembolso} />
                <Column style={{ width: '150px', color: "white", backgroundColor: "#041E42" }} footer={totalCartaoReembolso} />
                <Column style={{ width: '150px', color: "white", backgroundColor: "#041E42" }} footer={totalComissaoReembolso} />
                <Column style={{ width: '150px', color: "white", backgroundColor: "#041E42" }} footer={totalIncentivoReembolso} />
                <Column style={{ width: '150px', color: "white", backgroundColor: "#041E42" }} footer={totalDescontoReembolso} />
                <Column style={{ width: '150px', color: "white", backgroundColor: "#041E42" }} footer={totalLiquidoReembolso} />
            </Row>
        </ColumnGroup>;

    return (
        <DataTable header={header} size="small" stripedRows rowGroupFooterTemplate={reactFooter} value={reembolsos} groupRowsBy="numreemb"
            responsiveLayout="scroll" rowGroupMode="subheader" footerColumnGroup={footerGeral}
            sortMode="single" sortOrder={1} scrollable scrollHeight="500px" scrollDirection="both" >
            <Column bodyStyle={{ width: '400px' }} headerStyle={{ backgroundColor: '#041E42', color: 'white', width: '400px' }} field={paxBodyTemplate} header="Passageiro"></Column>
            <Column bodyStyle={{ width: '150px' }} headerStyle={{ backgroundColor: '#041E42', color: 'white', width: '150px' }} field={dataEmissaoBodyTemplate} header="Emissão"></Column>
            
            
            { tipoReembolso !== "TERRESTRE"  ?
            <Column bodyStyle={{ width: '200px' }} headerStyle={{ backgroundColor: '#041E42', color: 'white', width: '200px' }} field="doc" header="Tipo Doc."></Column>
                : 
            <Column bodyStyle={{ width: '200px' }} headerStyle={{ backgroundColor: '#041E42', color: 'white', width: '200px' }} field="tipoprod" header="Produto"></Column> 
            }
            
            <Column bodyStyle={{ width: '200px' }} headerStyle={{ backgroundColor: '#041E42', color: 'white', width: '200px' }} field="numreemb" header="Número Doc."></Column> 
            
            { tipoReembolso !== "TERRESTRE"  ?
            <Column bodyStyle={{ width: '300px' }} headerStyle={{ backgroundColor: '#041E42', color: 'white', width: '300px' }} field="numtkt" header="Ticket / Loc"></Column>
                : 
            <Column bodyStyle={{ width: '300px' }} headerStyle={{ backgroundColor: '#041E42', color: 'white', width: '300px' }} field="numresv" header="Nº Reserva"></Column> 
            }
            
            <Column bodyStyle={{ width: '150px' }} headerStyle={{ backgroundColor: '#041E42', color: 'white', width: '150px' }} field={tarifaBodyTemplate} header="Tarifa"></Column>
            <Column bodyStyle={{ width: '150px' }} headerStyle={{ backgroundColor: '#041E42', color: 'white', width: '150px' }} field={taxaBodyTemplate} header="Taxa"></Column>
            <Column bodyStyle={{ width: '150px' }} headerStyle={{ backgroundColor: '#041E42', color: 'white', width: '150px' }} field={multaBodyTemplate} header="Multa"></Column>
            <Column bodyStyle={{ width: '150px' }} headerStyle={{ backgroundColor: '#041E42', color: 'white', width: '150px' }} field={cartaoBodyTemplate} header="Cartão"></Column>
            <Column bodyStyle={{ width: '150px' }} headerStyle={{ backgroundColor: '#041E42', color: 'white', width: '150px' }} field={comissaoBodyTemplate} header="Comissão"></Column>
            <Column bodyStyle={{ width: '150px' }} headerStyle={{ backgroundColor: '#041E42', color: 'white', width: '150px' }} field={incentivoBodyTemplate} header="Incetivo"></Column>
            <Column bodyStyle={{ width: '150px' }} headerStyle={{ backgroundColor: '#041E42', color: 'white', width: '150px' }} field={descontoBodyTemplate} header="Desconto"></Column>
            <Column bodyStyle={{ width: '150px' }} headerStyle={{ backgroundColor: '#041E42', color: 'white', width: '150px' }} field={liquidoBodyTemplate} header="Líquido"></Column>
        </DataTable>
    );
}

export default TabelaReembolsos;
