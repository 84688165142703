import { createGlobalStyle } from "styled-components";


export const GlobalStyle = createGlobalStyle`
* {
  box-sizing: border-box;
  font-family: "Montserrat", sans-serif;
  margin: 0;
  padding: 0;
  text-decoration: none;
}

.MuiDataGrid-columnsContainer{
    background-color:#041E42;
    color:white;
    font-weight:bold;
    font-size:14px;
}
.p-tabview-panels{
  padding:0 !important;
}
.MuiDataGrid-sortIcon,.MuiSvgIcon-fontSizeSmall {
  color:white !important;
}

body {
  
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  *-moz-osx-font-smoothing: grayscale;
  height:100%;
  display: flex;
	flex-direction: column;
  text-rendering:optimizeLegibility;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}
`;



export default GlobalStyle;
