import { createTheme, responsiveFontSizes } from "@material-ui/core"

let theme = createTheme({
    palette: {
      primary: {
        main: "#041E42",
        contrastText: "white" 
      },
    }
});

theme = responsiveFontSizes(theme)
export default theme