import RequestPageIcon from '@mui/icons-material/RequestPage';
//import CurrencyExchangeTwoToneIcon from '@mui/icons-material/CurrencyExchangeTwoTone';
import MiscellaneousServicesIcon from '@mui/icons-material/MiscellaneousServices';

const LinksMenu = [
    //{
    //   label: "Usuários",
    //    url: "/users",
    //    disabled:"true"
    // },

    {
        label: "Configurações",
        url: "/redefinirSenha",
        disabled: "false",
        icon:<MiscellaneousServicesIcon color='primary'/>
    },
    //{
      //  label: "Reembolso",
      //  url: "/reembolso",
      //  disabled: "false",
      //  icon:<CurrencyExchangeTwoToneIcon color='primary'/>
    //},
    {
        label: "Fatura",
        url: "/fatura",
        disabled: "false",
        icon:<RequestPageIcon color='primary'/>
    }
];

//const urlBackend = 'http://localhost:8080'
const urlBackend = 'https://faturas.befly.com.br/'
//const urlBackend = 'http://10.10.60.60'


const TypePublicPlaces = [
    "Aeroporto", "Alameda", "Área", "Avenida", "Campo", "Chácara", "Colônia", "Condomínio",
    "Conjunto", "Distrito", "Esplanada", "Estação", "Estrada", "Favela", "Fazenda", "Feira",
    "Jardim", "Ladeira", "Lago", "Lagoa", "Largo", "Loteamento", "Morro", "Núcleo", "Parque",
    "Passarela", "Pátio", "Praça", "Quadra", "Recanto", "Residencial", "Rodovia", "Rua",
    "Setor", "Sítio", "Travessa", "Trecho", "Trevo", "Vale", "Vereda", "Via", "Viaduto",
    "Viela", "Vila"
]

const Estados = [
    "Acre",
    "Alagoas",
    "Amapá",
    "Amazonas",
    "Bahia",
    "Ceará",
    "Distrito Federal",
    "Espírito Santo",
    "Goiás",
    "Maranhão",
    "Mato Grosso",
    "Mato Grosso do Sul",
    "Minas Gerais",
    "Pará",
    "Paraíba",
    "Paraná",
    "Pernambuco",
    "Piauí",
    "Rio de Janeiro",
    "Rio Grande do Norte",
    "Rio Grande do Sul",
    "Rondônia",
    "Roraima",
    "Santa Catarina",
    "São Paulo",
    "Sergipe",
    "Tocantins",
]


export { LinksMenu, TypePublicPlaces, Estados, urlBackend }


