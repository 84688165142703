import React, { useState, useEffect } from 'react';
import Footer from "../../main/Footer";
import TemporaryDrawer from "../../main/TemporayDrawer";
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import { useLocation } from "react-router-dom";
import Fab from '@mui/material/Fab';
import Grid from '@mui/material/Grid';
import moment from 'moment'
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import CardHeader from '@mui/material/CardHeader';
import Avatar from '@mui/material/Avatar';
import InfoIcon from '@mui/icons-material/Info';
import AirplaneTicketIcon from '@mui/icons-material/AirplaneTicket';
import CurrencyExchangeTwoToneIcon from '@mui/icons-material/CurrencyExchangeTwoTone';
import AnnouncementIcon from '@mui/icons-material/Announcement';
import RequestQuoteIcon from '@mui/icons-material/RequestQuote';
import BedroomParentIcon from '@mui/icons-material/BedroomParent';
import DirectionsCarIcon from '@mui/icons-material/DirectionsCar';
import AirportShuttleIcon from '@mui/icons-material/AirportShuttle';
import TabPanel from '@mui/lab/TabPanel';
import Tab from '@mui/material/Tab';
import TabContext from '@mui/lab/TabContext';
import TabList from '@mui/lab/TabList';
import LuggageIcon from '@mui/icons-material/Luggage';
import SecurityIcon from '@mui/icons-material/Security';
import PictureAsPdfIcon from '@mui/icons-material/PictureAsPdf';
import Backdrop from '@mui/material/Backdrop';
import CircularProgress from '@mui/material/CircularProgress';
import PaidIcon from '@mui/icons-material/Paid';
import { Link, useNavigate } from "react-router-dom";
import { NumericFormat } from 'react-number-format';
import ListAltIcon from '@mui/icons-material/ListAlt';
import TextSnippetIcon from '@mui/icons-material/TextSnippet';
import ContentPasteIcon from '@mui/icons-material/ContentPaste';

import TabelaBilhetes from './tabelas/TabelaBilhetes';
import TabelaReembolsos from './tabelas/TabelaReembolsos';
import TabelaOcorrencias from './tabelas/TabelaOcorrencias';
import TabelaNotasFiscais from './tabelas/TabelaNotasFiscais';
import TabelaHoteis from './tabelas/TabelaHoteis';
import TabelaCarros from './tabelas/TabelaCarros';
import TabelaServicos from './tabelas/TabelaServicos';
import TabelaSeguros from './tabelas/TabelaSeguros';
import TabelaOnibus from './tabelas/TabelaOnibus';

import styled from "styled-components";
import ReportFaturaService from '../../api/services/ReportFaturaService';
import { getCodEmpUser } from '../../api/services/AuthService';


const Header = styled(Box)`   
    margin-top:10px;
`
const Layout = styled.div`   
    background-color:white;
    font-weight: bold;
    width:95%;
    height:auto;
    min-height: 700px;
    top:80px;
    margin-top:20px;
    align-items: center;
    justify-content: center;
    margin:0 auto;
    margin-top:120px;
`

const Titulo = styled(Typography)`
    color:#041E42;
    font-weight:bold;
    width:100%;
`

const LabelSpan = styled.span`
    font-weight:normal;
`

const TabPanelCustomer = styled(TabPanel)`
    width:100%;
    padding: 0 !important;
`

const LinkPdf = styled(Fab)`
    float:left;
    color:#FFFF !important;
    background-color:#B22222 !important;
`

const LinkCnf = styled(Fab)`
    float:left;
    color:#FFFF !important;
    background-color:#008000 !important;
`

const LinkObjection = styled(Fab)`
    float:left;
    color:#FFFF !important;
    background-color:#DAA520 !important;
`
const LinkListarFatura = styled(Link)`
    float: left;
`

const LinkBoleto = styled(Fab)`
    float:left;
    color:#FFFF !important;
    background-color:#000000 !important;
    padding-rigth:10px;
`

const MsgTitulo = styled(Typography)`
    color:#041E42;
    font-weight:bold;
    width:45%;
    float: left;
`

const DetalharFatura = () => {
    const [numfat, setNumfat] = useState("");
    const [dataVen, setDataVen] = useState(new Date());
    const [emissao, setEmissao] = useState(new Date());
    const [valor, setValor] = useState("");
    const [email, setEmail] = useState("");
    const [telefone, setTelefone] = useState("");
    const [fax, setFax] = useState("");
    const [fatura, setFatura] = useState("");
    const [endereco, setEndereco] = useState("");
    const [empresa, setEmpresa] = useState("");
    const [codigo, setCodigo] = useState("");
    const [praca, setPraca] = useState("");
    const [cnpj, setCnpj] = useState("");
    const [tabelaBilhetes, setTabelaBilhetes] = useState({});
    const [tabelaHoteis, setTabelaHoteis] = useState({});
    const [tabelaReembolsos, setTabelaReembolsos] = useState({});
    const [tabelaOcorrencias, setTabelaOcorrencias] = useState({});
    const [tabelaNotas, setTabelaNotas] = useState({});
    const [tabelaCarros, setTabelaCarros] = useState({});
    const [tabelaOnibus, setTabelaOnibus] = useState({});
    const [tabelaServicos, setTabelaServicos] = useState({});
    const [tabelaSeguros, setTabelaSeguros] = useState({});
    const [activeIndex, setActiveIndex] = useState("1");
    const [containBilhetes, setContainBilhetes] = useState(true);
    const [containOcorrencias, setContainOcorrencias] = useState(true);
    const [containReembolsos, setContainReembolsos] = useState(true);
    const [containHoteis, setContainHoteis] = useState(true);
    const [containNotas, setContainNotas] = useState(true);
    const [containCarros, setContainCarros] = useState(true);
    const [containOnibus, setContainOnibus] = useState(true);
    const [containServicos, setContainServicos] = useState(true);
    const [containSeguros, setContainSeguros] = useState(true);
    const [open, setOpen] = useState(false);
    const [codest, setCodest] = useState("");
    const [condpag, setCondpag] = useState("");
    const [dataVendaFatura, setDataVendaFatura] = useState(new Date());
    const [possuiBoleto, setPossuiBoleto] = useState(false);


    const location = useLocation();

    useEffect(() => {
        if (location && location.state) {
            writeFields(location.state.fatura);

        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [fatura, numfat, location, containBilhetes, containOcorrencias, containReembolsos, containNotas,
        containHoteis, containServicos, containCarros, containOnibus, open, codest, condpag, dataVendaFatura]);

    const writeFields = (fatura) => {
        setFatura(fatura);
        setCodest(fatura.codest);
        setCondpag(fatura.condpag);
        setDataVendaFatura(fatura.dataVen);
        setEmissao(fatura.cabecalho.emissao);
        setPossuiBoleto(fatura.possuiBoleto);
        setEndereco(fatura.cabecalho.endereco + ", " + fatura.cabecalho.bairro + ", " +
            fatura.cabecalho.cidade + " - " + fatura.cabecalho.estado
            + ", CEP: " + fatura.cabecalho.cep);
        setEmpresa(fatura.cabecalho.nome);
        setPraca(fatura.cabecalho.praca);
        setCodigo(fatura.cabecalho.codigo);
        setCnpj(fatura.cabecalho.cnpj)
        setValor(fatura.cabecalho.valor);
        setNumfat(fatura.cabecalho.numero);
        setDataVen(fatura.cabecalho.vencimento);
        setTelefone(fatura.cabecalho.fone1);
        setFax(fatura.cabecalho.fax);
        setEmail(fatura.cabecalho.email);

        setContainBilhetes(fatura.bilhetes !== undefined && fatura.bilhetes.length > 0 ? true : false)
        setContainReembolsos(fatura.reembolsos !== undefined && fatura.reembolsos.length > 0 ? true : false);
        setContainOcorrencias(fatura.ocorrencias !== undefined && fatura.ocorrencias.length > 0 ? true : false);
        setContainNotas(fatura.notaFiscal !== undefined && fatura.notaFiscal.length > 0 ? true : false);
        setContainHoteis(fatura.hoteis !== undefined && fatura.hoteis.length > 0 ? true : false);
        setContainCarros(fatura.carros !== undefined && fatura.carros.length > 0 ? true : false);
        setContainOnibus(fatura.onibus !== undefined && fatura.onibus.length > 0 ? true : false);
        setContainServicos(fatura.servicos !== undefined && fatura.servicos.length > 0 ? true : false);
        setContainSeguros(fatura.seguros !== undefined && fatura.seguros.length > 0 ? true : false);

        if (!containBilhetes) {
            setActiveIndex("2");
            if (!containHoteis) {
                setActiveIndex("3");
                if (!containCarros) {
                    setActiveIndex("4");
                    if (!containOnibus) {
                        setActiveIndex("5");
                        if (!containSeguros) {
                            setActiveIndex("6");
                            if (!containServicos) {
                                setActiveIndex("7");
                                if (!containReembolsos) {
                                    setActiveIndex("8");
                                    if (!containOcorrencias) {
                                        setActiveIndex("9");
                                    }
                                }
                            }
                        }
                    }
                }
            }
        }
        setTabelaBilhetes({
            bilhetes: fatura.bilhetes,
            totalTarifaGeral: fatura.totalTarifa,
            totalTaxa: fatura.totalTaxa,
            totalCartao: fatura.totalCartao,
            totalComissao: fatura.totalComissao,
            totalIncentivo: fatura.totalIncentivo,
            totalDesconto: fatura.totalDesconto,
            totalImposto: fatura.totalImposto,
            totalFee: fatura.totalFee,
            totalTxacrt: fatura.totalTxacrt,
            totalTxDu: fatura.totalTxDu,
            totalLiquido: fatura.totalLiquido,
            totalVenda: fatura.totalVenda,
            totalFlyPay: fatura.totalFlyPay
        });

        setTabelaHoteis({
            hoteis: fatura.hoteis,
            totalDiariaHotel: fatura.totalDiariaHotel,
            totalTaxaHotel: fatura.totalTaxaHotel,
            totalCartaoHotel: fatura.totalCartaoHotel,
            totalComissaoHotel: fatura.totalComissaoHotel,
            totalIncentivoHotel: fatura.totalIncentivoHotel,
            totalDescontoHotel: fatura.totalDescontoHotel,
            totalFeeHotel: fatura.totalFeeHotel,
            totalLiquidoHotel: fatura.totalLiquidoHotel,
            totalTotalizadorDiariaHotel: fatura.totalTotalizadorDiariaHotel,
            totalExtrasHotel: fatura.totalExtrasHotel
        });

        setTabelaReembolsos({
            reembolsos: fatura.reembolsos,
            totalTarifaReembolso: fatura.totalTarifaReembolso,
            totalTaxaReembolso: fatura.totalTaxaReembolso,
            totalCartaoReembolso: fatura.totalCartaoReembolso,
            totalComissaoReembolso: fatura.totalComissaoReembolso,
            totalIncentivoReembolso: fatura.totalIncentivoReembolso,
            totalDescontoReembolso: fatura.totalDescontoReembolso,
            totalMultaReembolso: fatura.totalMultaReembolso,
            totalLiquidoReembolso: fatura.totalLiquidoReembolso
        });

        setTabelaNotas({
            notas: fatura.notaFiscal
        });
        setTabelaOcorrencias({
            ocorrencias: fatura.ocorrencias
        });

        setTabelaCarros({
            carros: fatura.carros,
            totalDiariaCarro: fatura.totalDiariaCarro,
            totalTaxaCarro: fatura.totalTaxaCarro,
            totalCartaoCarro: fatura.totalCartaoCarro,
            totalComissaoCarro: fatura.totalComissaoCarro,
            totalIncentivoCarro: fatura.totalIncentivoCarro,
            totalDescontoCarro: fatura.totalDescontoCarro,
            totalFeeCarro: fatura.totalFeeCarro,
            totalLiquidoCarro: fatura.totalLiquidoCarro,
            totalTotalizadorDiariaCarro: fatura.totalTotalizadorDiariaCarro,
            totalExtrasCarro: fatura.totalExtrasCarro
        });

        setTabelaOnibus({
            onibus: fatura.bus,
            totalDiariaOnibus: fatura.totalDiariaOnibus,
            totalTaxaOnibus: fatura.totalTaxaOnibus,
            totalCartaoOnibus: fatura.totalCartaoOnibus,
            totalComissaoOnibus: fatura.totalComissaoOnibus,
            totalIncentivoOnibus: fatura.totalIncentivoOnibus,
            totalDescontoOnibus: fatura.totalDescontoOnibus,
            totalFeeOnibus: fatura.totalFeeOnibus,
            totalLiquidoOnibus: fatura.totalLiquidoOnibus,
            totalTotalizadorDiariaOnibus: fatura.totalTotalizadorDiariaOnibus,
            totalExtrasOnibus: fatura.totalExtrasOnibus
        });

        setTabelaServicos({
            servicos: fatura.servicos,
            totalDiariaServico: fatura.totalDiariaServico,
            totalTaxaServico: fatura.totalTaxaServico,
            totalCartaoServico: fatura.totalCartaoServico,
            totalComissaoServico: fatura.totalComissaoServico,
            totalIncentivoServico: fatura.totalIncentivoServico,
            totalDescontoServico: fatura.totalDescontoServico,
            totalFeeServico: fatura.totalFeeServico,
            totalLiquidoServico: fatura.totalLiquidoServico,
            totalTotalizadorDiariaServico: fatura.totalTotalizadorDiariaServico,
            totalExtrasServico: fatura.totalExtrasServico
        });

        setTabelaSeguros({
            seguros: fatura.seguros,
            totalDiariaSeguro: fatura.totalDiariaSeguro,
            totalTaxaSeguro: fatura.totalTaxaSeguro,
            totalCartaoSeguro: fatura.totalCartaoSeguro,
            totalComissaoSeguro: fatura.totalComissaoSeguro,
            totalIncentivoSeguro: fatura.totalIncentivoSeguro,
            totalDescontoSeguro: fatura.totalDescontoSeguro,
            totalFeeSeguro: fatura.totalFeeSeguro,
            totalLiquidoSeguro: fatura.totalLiquidoSeguro,
            totalTotalizadorDiariaSeguro: fatura.totalTotalizadorDiariaSeguro,
            totalExtrasSeguro: fatura.totalExtrasSeguro
        });
    }
    const handleChange = (event: React.SyntheticEvent, newValue: string) => {
        setActiveIndex(newValue);
    };
    const service = new ReportFaturaService();
    const downloadPdf = () => {
        setOpen(true);
        let user = getCodEmpUser();
        if (user !== null) {
            service.getFile('/getPdfLoaded/',
                fatura
            ).then(response => {
                const blobFile = new Blob([response.data], { type: response.headers['content-type'] });
                let url = window.URL.createObjectURL(blobFile);
                let a = document.createElement('a');
                a.href = url;
                a.download = numfat === 0 ? 'aFaturar.pdf' : numfat + '.pdf';
                a.click();
            }).catch(erro => {
                //setError(true);
            }).finally(() => {
                setOpen(false);
            })
        } else {
            navigate('/login', { state: { expired: true } })
        }
    }

    const downloadObjection = (event, cellValues) => {
        setOpen(true);
        service.getFile('/getObjection/',
            {}
        ).then(response => {
            const blobFile = new Blob([response.data], { type: 'application/excel' });
            let url = window.URL.createObjectURL(blobFile);
            let a = document.createElement('a');
            a.href = url;
            a.download = 'Formulário de contestação.xlsx';
            a.click();
        }).catch(erro => {
            console.log('Error name: ', erro.name)
            console.log('Error message: ', erro.message)
            console.log('Error stack: ', erro.stack)
        }).finally(() => {
            setOpen(false);
        })
    }

    const downloadPlanilha = (event, cellValues) => {
        setOpen(true);
        service.getFile('/getCsvLoaded/',
            fatura
        ).then(response => {
            const blobFile = new Blob([response.data], { type: 'application/csv' });
            let url = window.URL.createObjectURL(blobFile);
            let a = document.createElement('a');
            a.href = url;
            a.download = numfat + '.cnf';
            a.click();
        }).catch(erro => {
            console.log('Error name: ', erro.name)
            console.log('Error message: ', erro.message)
            console.log('Error stack: ', erro.stack)
        }).finally(() => {
            setOpen(false);
        })
    }

    const downloadBoleto = (event, cellValues) => {
        setOpen(true);
        service.getFile('/getBoleto/',
            {
                invoiceNumber: numfat,
            }
        ).then(response => {
            const blobFile = new Blob([response.data], { type: response.headers['content-type'] });
            let url = window.URL.createObjectURL(blobFile);
            let a = document.createElement('a');
            a.href = url;
            a.download = numfat + '.pdf';
            a.click();
        }).catch(erro => {
            console.log('Error name: ', erro.name)
            console.log('Error message: ', erro.message)
            console.log('Error stack: ', erro.stack)
        }).finally(() => {
            setOpen(false);
        })
    }
    let navigate = useNavigate();

    const viewListagem = () => {
        navigate('/detalharFatura', { replace: true, state: { fatura: fatura } })
    }
    return (
        <>
            <TemporaryDrawer />
            <Layout className="container">
                <Backdrop sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }} open={open}>
                    <CircularProgress color="inherit" />
                </Backdrop>
                <Header>
                    <Titulo>
                        <Grid container spacing={2} style={{ marginBotton: '1em' }}>
                            <Grid item xs={possuiBoleto === true ? 9.5 : 10}>
                                <MsgTitulo variant="h5" gutterBottom component="div">DETALHE DA FATURA</MsgTitulo>
                            </Grid>
                            <Grid item xs={0.5}>
                                <LinkListarFatura loading='true' to='/fatura' onClick={viewListagem} title="Listar Faturas">
                                    <Fab sx={{ zIndex: 0 }} variant="contained" color="primary" aria-label="add" size="small">
                                        <ListAltIcon />
                                    </Fab>
                                </LinkListarFatura>
                            </Grid>
                            <Grid item xs={0.5}>
                                <LinkPdf sx={{ zIndex: 0 }} onClick={downloadPdf} aria-label="add" size="small" title="Gerar PDF">
                                    <PictureAsPdfIcon />
                                </LinkPdf>
                            </Grid>
                            <Grid item xs={0.5}>
                                <LinkCnf sx={{ zIndex: 0 }} onClick={downloadPlanilha} aria-label="add" size="small" title="Gerar CNF">
                                    <TextSnippetIcon />
                                </LinkCnf>
                            </Grid>
                            <Grid item xs={0.5}>
                                <LinkObjection sx={{ zIndex: 0 }} onClick={downloadObjection} aria-label="add" size="small" title="Formulário de Contestação">
                                    <ContentPasteIcon />
                                </LinkObjection>
                            </Grid>
                            {possuiBoleto === true ? <Grid item  xs={0.5}>
                                <LinkBoleto sx={{ zIndex: 0 }} onClick={downloadBoleto} aria-label="add" size="small" title="Gerar Boleto">
                                    <PaidIcon />
                                </LinkBoleto>
                            </Grid> : null}
                        </Grid>
                    </Titulo>
                </Header>
                <Card sx={{ border: '1px solid #E0FFFF', minWidth: 800, backgroundColor: "#F5F5F5", flexGrow: 1, marginTop: 3 }}>
                    <CardHeader
                        sx={{ height: '20px', paddingLeft: 2, paddingTop: 4, paddingBottom: 4 }}
                        titleTypographyProps={{ fontSize: "24px", color: "#041E42" }}
                        title="Informações Gerais"
                        avatar={
                            <Avatar variant="square" sx={{ backgroundColor: "#041E42", width: "30px", height: "30px" }} aria-label="recipe">
                                <InfoIcon />
                            </Avatar>
                        }
                    >
                    </CardHeader>
                    <CardContent>
                        <Grid container spacing={2} style={{ marginBotton: '1em' }}>
                            <Grid item xs={10}>
                                <span>Empresa: </span><LabelSpan>{empresa}</LabelSpan><span> - </span><LabelSpan>{cnpj}</LabelSpan>
                            </Grid>
                            <Grid item xs={10}>
                                <span>Endereço: </span><LabelSpan>{endereco}</LabelSpan>
                            </Grid>
                            <Grid item xs={3}>
                                <span>Código: </span><LabelSpan>{codigo}</LabelSpan>
                            </Grid>
                            <Grid item xs={8}>
                                <span>Praça: </span><LabelSpan>{praca}</LabelSpan>
                            </Grid>
                            <Grid item xs={3}>
                                <span>Telefone: </span><LabelSpan>{telefone}</LabelSpan>
                            </Grid>
                            <Grid item xs={3}>
                                <span>Fax: </span><LabelSpan>{fax}</LabelSpan>
                            </Grid>
                            <Grid item xs={6}>
                                <span>Email: </span><LabelSpan>{email}</LabelSpan>
                            </Grid>
                            <Grid item xs={3}>
                                <span>Nº Fatura: </span><LabelSpan>{numfat}</LabelSpan>
                            </Grid>
                            <Grid item xs={3}>
                                <span>{numfat === 0 ? "Valor Pré-Fatura: " : "Valor: "}</span><LabelSpan>{<NumericFormat decimalScale={2} value={valor} displayType={'text'} thousandSeparator="." decimalSeparator="," prefix={'R$'} />}</LabelSpan>
                            </Grid>
                            <Grid item xs={3}>
                                <span>Emissão: </span><LabelSpan>{moment(emissao).format('DD/MM/YYYY')}</LabelSpan>
                            </Grid>
                            <Grid item xs={3}>
                                <span>Vencimento: </span><LabelSpan>{moment(dataVen).format('DD/MM/YYYY')}</LabelSpan>
                            </Grid>
                        </Grid>
                    </CardContent>
                </Card>
                <Box sx={{ width: '100%', typography: 'body1', marginBottom: 10 }}>
                    <TabContext value={activeIndex}>
                        <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
                            <TabList onChange={handleChange} aria-label="lab API tabs example">
                                <Tab key={"tabBilhete"} sx={{ display: containBilhetes ? "flex" : "none" }} iconposition="start" icon={<AirplaneTicketIcon />} label="Bilhetes" value="1" wrapped />
                                <Tab key={"tabHotel"} sx={{ display: containHoteis ? "flex" : "none" }} iconposition="start" icon={<BedroomParentIcon />} label="Hotéis" value="2" wrapped />
                                <Tab key={"tabCarro"} sx={{ display: containCarros ? "flex" : "none" }} iconposition="start" icon={<DirectionsCarIcon />} label="Locação" value="3" wrapped />
                                <Tab key={"tabOnibus"} sx={{ display: containOnibus ? "flex" : "none" }} iconposition="start" icon={<AirportShuttleIcon />} label="Rodoviário" value="4" wrapped />
                                <Tab key={"tabSeguro"} sx={{ display: containSeguros ? "flex" : "none" }} iconposition="start" icon={<SecurityIcon />} label="Seguros" value="5" wrapped />
                                <Tab key={"tabServico"} sx={{ display: containServicos ? "flex" : "none" }} iconposition="start" icon={<LuggageIcon />} label="Serviços" value="6" wrapped />
                                <Tab key={"tabReembolso"} sx={{ display: containReembolsos ? "flex" : "none" }} iconposition="start" icon={<CurrencyExchangeTwoToneIcon />} label="Reembolsos" value="7" wrapped />
                                <Tab key={"tabOcorrencia"} sx={{ display: containOcorrencias ? "flex" : "none" }} iconposition="start" icon={<AnnouncementIcon />} label="Ocorrências" value="8" wrapped />
                                <Tab key={"tabNota"} sx={{ display: containNotas ? "flex" : "none" }} iconposition="start" icon={<RequestQuoteIcon />} label="Nota Fiscal" value="9" wrapped />
                            </TabList>
                        </Box>
                        <TabPanelCustomer value="1" >
                            <Card sx={{ border: '1px solid #E0FFFF', minWidth: 800, backgroundColor: "#F5F5F5", flexGrow: 1, marginTop: 1 }}>
                                <CardContent>
                                    <TabelaBilhetes fatura={tabelaBilhetes} icon={<DirectionsCarIcon />}></TabelaBilhetes>
                                </CardContent>
                            </Card>
                        </TabPanelCustomer>
                        <TabPanelCustomer value="2">
                            <Card sx={{ border: '1px solid #E0FFFF', minWidth: 800, backgroundColor: "#F5F5F5", flexGrow: 1, marginTop: 1 }}>
                                <CardContent>
                                    <TabelaHoteis fatura={tabelaHoteis} icon={<BedroomParentIcon />}></TabelaHoteis>
                                </CardContent>
                            </Card>
                        </TabPanelCustomer>
                        <TabPanelCustomer value="3">
                            <Card sx={{ border: '1px solid #E0FFFF', minWidth: 800, backgroundColor: "#F5F5F5", flexGrow: 1, marginTop: 1 }}>
                                <CardContent>
                                    <TabelaCarros fatura={tabelaCarros} icon={<DirectionsCarIcon />}></TabelaCarros>
                                </CardContent>
                            </Card>
                        </TabPanelCustomer>
                        <TabPanelCustomer value="4">
                            <Card sx={{ border: '1px solid #E0FFFF', minWidth: 800, backgroundColor: "#F5F5F5", flexGrow: 1, marginTop: 1 }}>
                                <CardContent>
                                    <TabelaOnibus fatura={tabelaOnibus} icon={<AirportShuttleIcon />}></TabelaOnibus>
                                </CardContent>
                            </Card>
                        </TabPanelCustomer>
                        <TabPanelCustomer value="5">
                            <Card sx={{ border: '1px solid #E0FFFF', minWidth: 800, backgroundColor: "#F5F5F5", flexGrow: 1, marginTop: 1 }}>
                                <CardContent>
                                    <TabelaSeguros fatura={tabelaSeguros} icon={<SecurityIcon />}></TabelaSeguros>
                                </CardContent>
                            </Card>
                        </TabPanelCustomer>
                        <TabPanelCustomer value="6">
                            <Card sx={{ border: '1px solid #E0FFFF', minWidth: 800, backgroundColor: "#F5F5F5", flexGrow: 1, marginTop: 1 }}>
                                <CardContent>
                                    <TabelaServicos fatura={tabelaServicos} icon={<LuggageIcon />}></TabelaServicos>
                                </CardContent>
                            </Card>
                        </TabPanelCustomer>
                        <TabPanelCustomer value="7">
                            <Card sx={{ border: '1px solid #E0FFFF', minWidth: 800, backgroundColor: "#F5F5F5", flexGrow: 1, marginTop: 1 }}>
                                <CardContent>
                                    <TabelaReembolsos tipoReembolso={fatura.invoiceType} fatura={tabelaReembolsos} ></TabelaReembolsos>
                                </CardContent>
                            </Card>
                        </TabPanelCustomer>
                        <TabPanelCustomer value="8">
                            <Card sx={{ border: '1px solid #E0FFFF', minWidth: 800, backgroundColor: "#F5F5F5", flexGrow: 1, marginTop: 1 }}>
                                <CardContent>
                                    <TabelaOcorrencias fatura={tabelaOcorrencias}></TabelaOcorrencias>
                                </CardContent>
                            </Card>
                        </TabPanelCustomer>
                        <TabPanelCustomer value="9">
                            <Card sx={{ border: '1px solid #E0FFFF', minWidth: 800, backgroundColor: "#F5F5F5", flexGrow: 1, marginTop: 1 }}>
                                <CardContent>
                                    <TabelaNotasFiscais fatura={tabelaNotas} tipoProduto={fatura.invoiceType}></TabelaNotasFiscais>
                                </CardContent>
                            </Card>
                        </TabPanelCustomer>
                    </TabContext>
                </Box>
            </Layout>
            <Footer />
        </>
    );
}

export default DetalharFatura;