import axios from 'axios';
import {urlBackend} from '../../main/Util'
axios.defaults.headers.post['Content-Type'] ='application/x-www-form-urlencoded';
axios.defaults.headers.post['Content-Type'] ='application/json;charset=utf-8';
axios.defaults.headers.post['Access-Control-Allow-Origin'] = 'http://localhost:3000';
axios.defaults.headers.post['Cache-control'] = 'no-cache';

export const axiosLogin = axios.create({
    baseURL: urlBackend,
    withCredentials: true   
});

class LoginService{


    constructor(){
        this.apiUrl = '/portal/api'
    }

    autenticar(credentials){
        const requestUrl = `${this.apiUrl}/autenticar`
        return axiosLogin.post(requestUrl, credentials)
    }
    resetar(credentials){
        const requestUrl = `${this.apiUrl}/resetar`
        return axiosLogin.post(requestUrl, credentials);
    }
    recuperarSenha(email){
        const requestUrl = `${this.apiUrl}/recuperarSenha`
        return axiosLogin.post(requestUrl, email)
    }
    clearStorage() {
        sessionStorage.setItem("numeroFatura", '');
        sessionStorage.setItem("produto", "TODOS");
    }
}

export default LoginService;