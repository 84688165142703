import React from 'react';
import CardHeader from '@mui/material/CardHeader';
import Avatar from '@mui/material/Avatar';
import SearchIcon from '@mui/icons-material/Search';


const CardHeaderFilterSearch = () => {
    return (
        <CardHeader
            sx={{ height: '20px', paddingLeft: 0, marginBottom: 2 }}
            titleTypographyProps={{ fontSize: "24px", color: "#041E42" }}
            title="Filtros de Busca"
            avatar={
                <Avatar variant="square" sx={{ backgroundColor: "#041E42", width: "30px", height: "30px" }} aria-label="recipe">
                    <SearchIcon />
                </Avatar>
            }
        >
        </CardHeader>
    );
};

export default CardHeaderFilterSearch;
