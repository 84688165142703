import React, { useState, useEffect } from 'react';
import { getCodEmpUser, getUsername, verifyFirstLogin, setSessionActive, setPasswordFull } from '../../api/services/AuthService';
import MuiAlert from '@mui/material/Alert';
import { useNavigate } from 'react-router-dom';
import TemporaryDrawer from '../../main/TemporayDrawer';
import { motion } from "framer-motion";
import Backdrop from '@mui/material/Backdrop';
import CircularProgress from '@mui/material/CircularProgress';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Footer from '../../main/Footer';
import styled from "styled-components";
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import Avatar from '@mui/material/Avatar';
import CardHeader from '@mui/material/CardHeader';
import PasswordIcon from '@mui/icons-material/Password';
import OutlinedInput from '@mui/material/OutlinedInput';
import InputLabel from '@mui/material/InputLabel';
import FormControl from '@mui/material/FormControl';
import InputAdornment from '@mui/material/InputAdornment';
import IconButton from '@mui/material/IconButton';
import Visibility from '@mui/icons-material/Visibility';
import VisibilityOff from '@mui/icons-material/VisibilityOff';
import Button from '@mui/material/Button';
import ResetService from '../../api/services/ResetService';
import PasswordStrengthBar from 'react-password-strength-bar';
import Snackbar from '@mui/material/Snackbar';
import Grid from '@mui/material/Grid';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemText from '@mui/material/ListItemText';
import LockResetIcon from '@mui/icons-material/LockReset';

const Layout = styled.div`   
    background-color:white;
    font-weight: bold;
    width:95%;
    height:600px;
    top:80px;
    margin-top:20px;
    align-items: center;
    justify-content: center;
    margin:0 auto;
    margin-top:100px;
`
const Header = styled(Box)`   
    margin-top:10px;
`

const Titulo = styled(Typography)`
    color: #041E42;
    font-weight:bold;
    width:100%;
    margin-botton:20px;
`

const StrenghtBar = styled(PasswordStrengthBar)`
    width:300px;
`

const ResetSenha = () => {
    const [senhaAtual, setSenhaAtual] = useState("");
    const [novaSenha, setNovaSenha] = useState("");
    const [helper, setHelper] = useState("Preencha os campos!");
    const [type, setType] = useState("warning");
    const [confirmacaoNovaSenha, setConfirmacaoNovaSenha] = useState("");
    const [open, setOpen] = useState(false);
    const [showSenhaAtual, setShowSenhaAtual] = useState(false);
    const [showNovaSenha, setShowNovaSenha] = useState(false);
    const [showConfirmacaoNovaSenha, setShowConfirmacaoNovaSenha] = useState(false);
    const [tipoSnack, setTipoSnack] = useState("warning");
    const [openSnack, setOpenSnack] = useState(false);
    const [message, setMessage] = useState("");
    const [levelPassword, setLevelPassword] = useState(0);


    useEffect(() => {
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [helper, type, senhaAtual, confirmacaoNovaSenha, novaSenha, levelPassword]);

    const Alert = React.forwardRef(function Alert(props, ref) {
        return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
    });

    let navigate = useNavigate();
    const servico = new ResetService();

    const validar = () => {
        let retorno = true;
        let message = '';
        if ((!senhaAtual || !novaSenha || !confirmacaoNovaSenha)) {
            message += "Preeencha todos os campos para o redefinir a senha!\n";
            retorno = false;
        }
        if (novaSenha !== confirmacaoNovaSenha) {
            message += "Senha e Confirmação são diferentes!\n";
            retorno = false;
        }
        if (levelPassword < 3) {
            message += "Senha não atende os requistos! Utilize a especificação mostrada na tela para cadastrar uma senha !\n";
            retorno = false;
        }
        if (novaSenha === senhaAtual) {
            message += "A nova senha digitada é igual a senha atual. Crie uma nova senha !\n";
            retorno = false;
        }
        if (!retorno) {
            setMessage(message);
            setOpenSnack(true);
            setTipoSnack("warning");
            setOpen(false);
        }
        return retorno;
    }

    const scoreWords = ['Muito Fraca', 'Fraca', 'Média', 'Forte', 'Muito Forte'];
    const barColors = ['#808080', '#ef4836', '#FFD700', '#2b90ef', '#25c281'];

    const changeLevelPassword = (score, feedback) => {
        setLevelPassword(score)
    }

    const createSession = (jsonToken) => {
        return verifyFirstLogin(jsonToken);
    };

    const saveNewPassword = () => {
        setOpen(true);
        let codemp = getCodEmpUser();
        if (codemp !== null) {
            if (validar()) {
                servico.resetar({
                    username: getUsername(),
                    oldPassword: senhaAtual,
                    newPassword: novaSenha,
                    confirmNewPassword: confirmacaoNovaSenha,
                    codemp: codemp
                }).then(response => {
                    setMessage("Senha Alterada com Sucesso!");
                    setOpenSnack(true);
                    setTipoSnack("success");
                    const jsonToken = response.data;
                    createSession(jsonToken);
                    setSessionActive();
                    setPasswordFull();
                }).catch(erro => {
                    console.log('Error name: ', erro.name)
                    console.log('Error message: ', erro.message)
                    console.log('Error stack: ', erro.stack)
                    setMessage("Erro");
                    setOpenSnack(true);
                    setTipoSnack("error");
                }).finally(() => {
                    setOpen(false);
                })
            }
        } else {
            navigate('/login', { state: { expired: true } })
        }
    }

    const handleClickShowSenhaAtual = () => setShowSenhaAtual((show) => !show);
    const handleClickShowNovaSenha = () => setShowNovaSenha((show) => !show);
    const handleClickShowConfirmacaoNovaSenha = () => setShowConfirmacaoNovaSenha((show) => !show);

    const handleMouseDownPassword = (event: React.MouseEvent<HTMLButtonElement>) => {
        event.preventDefault();
    };

    const clear = () => {
        setSenhaAtual("");
        setNovaSenha("");
        setConfirmacaoNovaSenha("");
        setShowSenhaAtual("");
        setShowNovaSenha("");
        setShowConfirmacaoNovaSenha("");
    }

    const handleChangeNovaSenha = (event) => {
        setNovaSenha(event.target.value);
        if (!event.target.value && !confirmacaoNovaSenha) {
            setHelper("Preencha os campos!");
            setType("warning");
        } else {
            if (!(event.target.value === confirmacaoNovaSenha)) {
                setHelper("Senhas Diferentes!");
                setType("warning");
            } else {
                setHelper("Senhas iguais!");
                setType("success");
            }
        }
    };


    const handleCloseSnack = (event, reason) => {
        if (reason === 'clickaway') {
            return;
        }
        setOpenSnack(false);
    };

    const handleChangeConfirmacaoNovaSenha = (event) => {
        setConfirmacaoNovaSenha(event.target.value);
        if (!novaSenha && !event.target.value) {
            setHelper("Preencha os campos!");
            setType("warning");
        } else {
            if (!(novaSenha === event.target.value)) {
                setHelper("Senhas Diferentes!");
                setType("warning");
            } else {
                setHelper("Senhas Iguais!");
                setType("success");
            }
        }
    }

    return (
        <>
            <TemporaryDrawer />
            <motion.div
                className='reembolso'
                initial={{ opacity: 0 }}
                animate={{ opacity: 1 }}
                exit={{ opacity: 0 }}>
                <Backdrop sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }} open={open}>
                    <CircularProgress color="inherit" />
                </Backdrop>
                <Layout className="container">
                    <Header>
                        <Titulo variant="h4" gutterBottom component="div">
                            CONFIGURAÇÕES
                        </Titulo>
                    </Header>
                    <Grid container spacing={2} style={{ marginBottom: '24px', paddingTop: '10px' }}>
                        <Grid item xs={6}>
                            <Card sx={{ border: '1px solid #E0FFFF', height:450, minWidth: 500, backgroundColor: "#F5F5F5", flexGrow: 1, marginTop: 3 }}>
                                <CardHeader
                                    sx={{ height: '20px', paddingLeft: 2, paddingTop: 4, paddingBottom: 4 }}
                                    titleTypographyProps={{ fontSize: "24px", color: "#041E42" }}
                                    title="Redefinição de Senha"
                                    avatar={
                                        <Avatar variant="square" sx={{ backgroundColor: "#041E42", width: "30px", height: "30px" }} aria-label="recipe">
                                            <LockResetIcon />
                                        </Avatar>
                                    }
                                >
                                </CardHeader>
                                <CardContent>
                                    <Box component="form">
                                        <FormControl sx={{ width: 300 }} variant="outlined">
                                            <InputLabel htmlFor="outlined-adornment-password">Senha Atual</InputLabel>
                                            <OutlinedInput
                                                id="outlined-adornment-password-1"
                                                type={showSenhaAtual ? 'text' : 'password'}
                                                size="small"
                                                value={senhaAtual}
                                                onChange={(event) => {
                                                    setSenhaAtual(event.target.value)
                                                }}
                                                autoComplete="off"
                                                sx={{ backgroundColor: "#FFFF" }}
                                                endAdornment={
                                                    <InputAdornment position="end">
                                                        <IconButton
                                                            aria-label="toggle password visibility"
                                                            onClick={handleClickShowSenhaAtual}
                                                            onMouseDown={handleMouseDownPassword}
                                                            edge="end"
                                                        >
                                                            {showSenhaAtual ? <VisibilityOff /> : <Visibility />}
                                                        </IconButton>
                                                    </InputAdornment>
                                                }
                                                label="Senha Atual"
                                            />
                                        </FormControl>
                                    </Box>
                                    <Box component="form" sx={{ marginTop: 2 }}>
                                        <FormControl sx={{ width: 300 }} variant="outlined">
                                            <InputLabel htmlFor="outlined-adornment-password">Nova Senha</InputLabel>
                                            <OutlinedInput
                                                id="outlined-adornment-password-2"
                                                type={showNovaSenha ? 'text' : 'password'}
                                                size="small"
                                                value={novaSenha}
                                                onChange={handleChangeNovaSenha}
                                                autoComplete="off"
                                                sx={{ backgroundColor: "#FFFF" }}
                                                endAdornment={
                                                    <InputAdornment position="end">
                                                        <IconButton
                                                            aria-label="toggle password visibility"
                                                            onClick={handleClickShowNovaSenha}
                                                            onMouseDown={handleMouseDownPassword}
                                                            edge="end"
                                                        >
                                                            {showNovaSenha ? <VisibilityOff /> : <Visibility />}
                                                        </IconButton>
                                                    </InputAdornment>
                                                }
                                                label="Nova Senha"
                                            />
                                        </FormControl>
                                        <StrenghtBar onChangeScore={changeLevelPassword} barColors={barColors} minLength={5} shortScoreWord={["Vazia"]} scoreWords={scoreWords} password={novaSenha} style={{ with: 300 }} />
                                    </Box>
                                    <Box component="form" sx={{ marginTop: 2 }}>
                                        <FormControl sx={{ width: 300 }} variant="outlined">
                                            <InputLabel htmlFor="outlined-adornment-password-3">Confirme Nova Senha</InputLabel>
                                            <OutlinedInput
                                                id="outlined-adornment-password"
                                                type={showConfirmacaoNovaSenha ? 'text' : 'password'}
                                                size="small"
                                                autoComplete="off"
                                                value={confirmacaoNovaSenha}
                                                onChange={handleChangeConfirmacaoNovaSenha}
                                                sx={{ backgroundColor: "#FFFF" }}
                                                endAdornment={
                                                    <InputAdornment position="end">
                                                        <IconButton
                                                            aria-label="toggle password visibility"
                                                            onClick={handleClickShowConfirmacaoNovaSenha}
                                                            onMouseDown={handleMouseDownPassword}
                                                            edge="end"
                                                        >
                                                            {showConfirmacaoNovaSenha ? <VisibilityOff /> : <Visibility />}
                                                        </IconButton>
                                                    </InputAdornment>
                                                }
                                                label="Confirme Nova Senha"
                                            />
                                        </FormControl>
                                        <Alert sx={{ marginTop: 2, width: 300 }} severity={type}>{helper}</Alert>
                                    </Box>
                                    <Button sx={{ width: 150, marginTop: 3 }} onClick={saveNewPassword} color="primary" variant="contained">Redefinir</Button>
                                    <Button sx={{ width: 150, marginLeft: 2.5, marginTop: 3 }} onClick={clear} color="primary" variant="contained">Limpar</Button>
                                </CardContent>
                            </Card>
                        </Grid>
                        <Grid item xs={6}>
                            <Card sx={{ border: '1px solid #E0FFFF',  height:450, minWidth: 600, backgroundColor: "#F5F5F5", flexGrow: 1, marginTop: 3 }}>
                                <CardHeader
                                    sx={{ height: '20px', paddingLeft: 2, paddingTop: 4, paddingBottom: 4 }}
                                    titleTypographyProps={{ fontSize: "24px", color: "#041E42" }}
                                    title="Regras para a criação de senha: "
                                    avatar={
                                        <Avatar variant="square" sx={{ backgroundColor: "#041E42", width: "30px", height: "30px" }} aria-label="recipe">
                                            <PasswordIcon />
                                        </Avatar>
                                    }
                                >
                                </CardHeader>
                                <CardContent>
                                    <Box component="form">
                                        <Typography variant="h6" component="h2">
                                            A administração do Portal do Agente exige a criação de senhas fortes! A criação de uma senha forte inclui os critérios:
                                        </Typography>
                                        <List>
                                            <ListItem key="1" color='primary' >
                                                <ListItemText sx={{ color: "#041E42" }} primary="Comprimento da senha: mínimo de oito caracteres;" color='primary' />
                                            </ListItem>
                                            <ListItem key="2" color='primary' >
                                                <ListItemText sx={{ color: "#041E42" }} primary="Caracteres numéricos: mínimo de dois números;" color='primary' />
                                            </ListItem>
                                            <ListItem key="3" color='primary' >
                                                <ListItemText sx={{ color: "#041E42" }} primary="Caracteres especiais: mínimo de um caractere especial;" color='primary' />
                                            </ListItem>
                                            <ListItem key="4" color='primary' >
                                                <ListItemText sx={{ color: "#041E42" }} primary="Letras maiúsculas: mínimo de uma letra maiúscula" color='primary' />
                                            </ListItem>
                                            <ListItem key="5" color='primary' >
                                                <ListItemText sx={{ color: "#041E42" }} primary="Letras minúsculas: mínimo de uma letra minúscula" color='primary' />
                                            </ListItem>
                                        </List>
                                    </Box>
                                </CardContent>
                            </Card>
                        </Grid>
                    </Grid>
                </Layout>
                <Snackbar open={openSnack} autoHideDuration={6000} onClose={handleCloseSnack}>
                    <Alert onClose={handleCloseSnack} severity={tipoSnack} sx={{ width: '100%', whiteSpace: 'pre-line' }}>
                        {message}
                    </Alert>
                </Snackbar>
            </motion.div>
            <Footer />
        </>

    );
}

export default ResetSenha;